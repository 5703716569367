import Slider from "./Slider";
import {Link} from 'react-router-dom';



export default function Flyout (){
    return (
        <div className="flyout">
            <div className="left">
                    <div className="sub">
                    <Link to='/women'className='fly'><p className="sub__liste"> <i class="fa-solid fa-person-dress"></i> Mode femme</p></Link>
                    </div>
                    <div className="sub">
                       <Link to='/men'className='fly'> <p className="sub__liste"><i class="fa-solid fa-person-dress"></i> Mode Homme</p></Link>
                    </div>
                    <div className="sub">
                    <Link to='/child'className='fly'><p className="sub__liste"><i class="fa-solid fa-baby"></i> Mode Bébé</p></Link>
                    </div>
                    <div className="sub">
                    <Link to='/house'className='fly'><p className="sub__liste"><i class="fa-solid fa-house"></i> Maison & Bureau</p></Link>
                    </div>
                    <div className="sub">
                    <Link to='/smartphones'className='fly'><p className="sub__liste"><i class="fa-solid fa-mobile"></i> Smartphones & Tablettes</p></Link>
                    </div>
                    <div className="sub">
                    <Link to='/supermarket'className='fly'><p className="sub__liste"><i class="fa-solid fa-shop"></i> Supermarché</p></Link>
                    </div>
                    <div className="sub">
                    <Link to='/informatiques'className='fly'> <p className="sub__liste"> <i class="fa-solid fa-laptop"></i> Informatiques</p></Link>
                    </div>
                    <div className="sub">
                    <Link to='/informatiques'className='fly'><p className="sub__liste"><i class="fa-solid fa-business-time"></i> Electroniques</p></Link>
                    </div>
                    <div className="sub">
                    <Link to='/beauty'className='fly'><p className="sub__liste"> <i class="fa-solid fa-wand-magic-sparkles"></i> Beauté & Hygiène</p></Link>
                    </div>
                    <div className="sub">
                    <Link to='/'className='fly'><p className="sub__liste"> <i class="fa-solid fa-list"></i> Autres categories</p></Link>
                    </div>
            </div>
            <div className="center">
                <div>
                    <h6 className="center__title">TOP BRANDS</h6>
                    <h2>NEW COLLECTION</h2>
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                    <button className="btn__shop">SHOP NOW</button>
                </div>
            </div>
            {/* <div className="right">
                <div className="flyout__right">
                     <img src="https://ci.jumia.is/cms/1_2023/W15/SX/CI_W15_ET_DESTOCKAGE_LRM.gif" alt="" />
                </div>
                <div className="flyout__right">
                    <img src="https://ci.jumia.is/cms/1_2023/W15/SX/CI_W15_ET_DESTOCKAGE_LRM.gif" alt="" />
                </div>
            </div> */}
        </div>
    )
}
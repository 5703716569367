import './App.css';
import {Routes,Route} from 'react-router-dom';
import Contact from './Pages/Contact';
import Detail from './Pages/Detail';
import 'animate.css';
import Article from './Pages/Article';
import Accueil from './Pages/Accueil';
// import Smartphone from './Pages/Smartphone';
import Accessoires from './Pages/Accessoires';
import Commandes from './Pages/Commandes';
import LogPage from './components/LogPage';
import Women from './Pages/Women';
import Men from './Pages/Men';
import Child from './Pages/Child';
import UserDetails from './components/UserDetails';
import SearchingPage from './Pages/SearchingPage';
import Smartphones from './Pages/Smartphones';
import Shoes from './Pages/Shoes';
import Beauty from './Pages/Beauty';
import Informatiques from './Pages/Informatiques';
import House from './Pages/House';
import Supermarket from './Pages/Supermarket';
import Details from './Pages/Details';



function App() {
  return (
    <Routes>
    <Route path="/" element={<Accueil/>}/>
    <Route path='/contact' element={<Contact/>} />
    <Route path='/details/:id' element={<Details/>} />
    <Route path='/detail/:id' element={<Detail/>} />
    <Route path='/article' element={<Article/>} />
    <Route path='/men' element={<Men/>}/>
    <Route path='/women' element={<Women/>}/>
    <Route path='/child' element={<Child/>}/>
    <Route path='/accessoires' element={<Accessoires/>}/>
    <Route path='/smartphones' element={<Smartphones/>}/>
    <Route path='/informatiques' element={<Informatiques/>}/>
    <Route path='/house' element={<House/>}/>
    <Route path='/shoes' element={<Shoes/>}/>
    <Route path='/beauty' element={<Beauty/>}/>
    <Route path='/supermarket' element={<Supermarket/>}/>
    {/* <Route path='/category/:slug' element={<Smartphone/>}/> */}
    <Route path='/cart' element={<Commandes/>}/>
    <Route path='/loginPage' element={<LogPage/>}/>
    <Route path='/userDetail' element={<UserDetails/>}/>
    <Route path='/searching' element={<SearchingPage/>}/>

  </Routes>
  );
}

export default App;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {addToCart, getAllArticles} from "../Redux/actions";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ProductCart from "../components/ProductCart";
import Slider from '../components/Slider';
import Marquee from "react-fast-marquee";
import Filter from "./Filter";



export default function Watches () {
    const dispatch = useDispatch();
    const Accesoires = useSelector(state=>state.categoryReducer.Accesoires);
    const [items,setCat]=useState(Accesoires);
    const [dis,setDis]=useState(true)


    const AddArticle = (id) => {
        dispatch(addToCart(id))
      }

    const filterResult = (catItem)=>{
      const filterData = Accesoires.filter((Accesoire) =>  Accesoire.categories === catItem )
          setCat(filterData);
          setDis(false)
  }

//     useEffect(() => {
//         fetch('http://127.0.0.1:8080/men')
//         .then((res)=>res.json())
//         .then((products)=>{dispatch(getAllArticles(products))
//         })
//         .catch(e => { console.log(e)})
//         }, [])


//     const handleChange = (event)=>{
//             setSearch(event.target.value)
//         }
     

    return (
        <div>
                <Marquee className="marquee">
                        I can be a React component, multiple React components, or just some text.
                 </Marquee>
            <Navbar/>
            <div className="container">
            <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Accessoires</li>
            </ol>
            </nav>
            <ProductCart/>
            <div className="part__products__header">
            <img src="https://tpc.googlesyndication.com/simgad/14821598599584974598" alt="" />
                <p className="container--header">Accessoires </p>
                <p>Découvrez les nouveautés de notre collection pour homme. Vous trouverez des must-haves de tous les jours tels que hauts et t-shirts, ensembles décontractés et sous-vêtements.
                         Et pour ce qui est des pantalons pour homme, il y a des joggings et des pantalons cargo de toutes les couleurs. 
                        Vous rêvez de denim ? Notre gamme de jeans pour homme offre un large choix de coupes, dont skinny entre autres. 
                        Portez-le vôtre avec une chemise oversize tendance ou une pièce en denim de notre sélection de chemises pour homme. </p>
                </div>
                {/* <Filter setDis={setDis} dis={dis} products={products} filterResult={filterResult}/> */}
            </div>
        <div className="container-contents">
          <div className="sidebar">
          <div className="filter--container">
          <h4>CATÉGORIE</h4>
            <h6>{items.length} résultat(s)</h6>
  
             <div className="col-3 col-md-12">
                    <p className="btn__filter" onClick={()=>{filterResult('Bracelets')}}>Bracelets</p>              
            </div>
            <div className="col-3 col-md-12">
                    <p className="btn__filter" onClick={()=>{filterResult('Bijoux')}}>Bijoux</p>              
            </div>
            <div className="col-3 col-md-12">
                    <p className="btn__filter" onClick={()=>{filterResult('Montres')}}>Montres</p>              
            </div>

            <div className="col-3 col-md-12">
                    <p className="btn__filter" onClick={()=>{filterResult('Chaussettes')}}>Chaussettes</p>         
            </div>
            <div className="col-3 col-md-12">
                    <p className="btn__filter" onClick={()=>{filterResult('Lunettes')}}>Lunettes</p>         
            </div>
                </div>
            </div>
            

            <div className="part__products">
        { dis  ? (   <div className="section__part__presentaions">
                            <div className="part__presentaions">
                                <div className="part__presentaions__pictures">
                                        <img src="https://pisces.bbystatic.com/image2/BestBuy_US/dam/SOL-105011-dp-hero-sv-3687ade8-4b42-491a-a339-5e5a4a232bfd.jpg" alt="" />
                                </div>
                                <div className="part__presentaions__descriptions">
                                        <h4>NEW GAMME DE PRODUIT</h4>
                                                <p>Révélez la beauté qui sommeille en vous !</p>
                                                <button className="btn__desc">Découvrir</button>
                                </div>
                        </div>

                        {/* <div className="part__presentaions">
                                <div className="part__presentaions__pictures">
                                        <img src="https://pisces.bbystatic.com/image2/BestBuy_US/dam/SOL-105011-dp-hero-sv-3687ade8-4b42-491a-a339-5e5a4a232bfd.jpg" alt="" />
                                </div>
                                <div className="part__presentaions__descriptions">
                                        <h4>NEW GAMME DE PRODUIT</h4>
                                                <p>Révélez la beauté qui sommeille en vous !</p>
                                                <button className="btn__desc">Découvrir</button>
                                </div>
                        </div> */}

                        <div className="section__part__presentaions__description">
                            <h5 className="desc__header">Pourquoi acheter votre matériel informatique sur EasyShopping Côte d’Ivoire ?</h5>
                            <p>L’informatique, encore appelé traitement automatique de l’information, n’a plus de frontière de nos jours. 
                                Elle est bien lointaine la création du tout premier ordinateur en 1946 ! Nombre d’améliorations technologiques ont eu lieu et il est clair de nos jours que l’informatique tient une place prépondérante dans notre vie quotidienne. 
                                Professionnels, particuliers et entreprises, avec EasyShopping Côte d’Ivoire le leader de la vente en ligne, renouvelez votre matériel informatique et améliorez votre productivité. Le leader de la vente en ligne en Côte d’Ivoire vous offre des articles informatiques variés : 
                                PC portables et ordinateurs de bureaux, imprimantes, stockage, antivirus et pleins d’autres matériels à moindre coût.</p>
                            
                            <h5 className="desc__header">Accessoires informatiques aux meilleurs prix</h5>
                            <p>Pour tout propriétaire ou utilisateur de matériel informatique, l’utilisation d’un ordinateur personnel ne suffit pas. 
                                Afin de devenir beaucoup plus efficace avec le travail que l'on effectue et également pour que le travail puisse être effectué facilement, 
                                certains accessoires informatiques de base sont nécessaires, ce qui aidera l'utilisateur pour cette cause. Vous trouverez ci-dessous certains des accessoires de base que tout propriétaire d’ordinateur devrait envisager de 
                                se procurer afin d’obtenir plus de ses appareils.</p>

                            <h5 className="desc__header">Casques d'écoute et housse de protection pc</h5>
                            <p>Qu'il s'agisse de casque ou d'écouteurs, les écouteurs constituent un autre accessoire important pour l'utilisateur qui doit taper à 
                                la machine et participer à des activités d'écoute et de conversation. Les casques peuvent être utilisés pour recevoir facilement des appels Skype ou tout 
                                autre type d'appels sur votre ordinateur de bureau ou votre ordinateur portable, sans vous distraire de toutes les autres activités que vous avez pu engager dans votre appareil. </p>
                            
                            <h5 className="desc__header">Les meilleurs ordinateurs portables dans lesquels investir</h5>
                            <p>Il est indéniable que les meilleures marques d'ordinateurs portables sont toujours 
                                l'outil idéal pour trouver le meilleur modèle d'ordinateur portable dans lequel vous pouvez investir. Outre les marques susmentionnées, 
                                Dell, Asus, Microsoft, Lenovo, Acer sont d'autres ordinateurs portables fiables et dignes de ce nom. leur prix.</p>

                            <h5 className="desc__header">Imprimantes Côte d'Ivoire : pour vos besoins d'impression quotidiens</h5>
                            <p>EasyShopping vous propose la plus large gamme d'imprimantes en Côte d'Ivoire. 
                                L'innovation technologique a apporté de nouveaux changements à notre mode de vie. La technologie d'impression a connu une transformation considérable au fil des ans. 
                                Les progrès technologiques nous ont permis d’imprimer des documents et des images de haute qualité en très peu de temps. Les imprimantes multifonctions constituent la solution idéale pour l’impression, la numérisation et la photocopie.
                                </p>
                            <h5 className="desc__header">Stockage de données et mémoire</h5>
                            <p>Nous avons une large gamme de produits de stockage de données. Notre gamme comprend des clés USB, des lecteurs de cartes, des clés USB, des disques durs et des cartes Sandisk, 
                                qui vous permettent de tirer le meilleur parti des capacités de votre PC. 
                                Les périphériques de stockage sont disponibles dans différents types hautes performances.
                                </p>
                            <h5 className="desc__header">Où acheter des accessoires informatiques en ligne</h5>
                            <p>Achetez des accessoires informatiques abordables en ligne sur EasyShopping. Vous pouvez obtenir de bonnes affaires sur des accessoires solides et durables des meilleures marques. Ici, vous trouverez des accessoires informatiques qui faciliteront la fonctionnalité de votre ordinateur personnel. Les accessoires sont essentiellement conçus pour aider, soutenir et permettre une expérience informatique sans heurt pour l'utilisateur.</p>

                            <h5 className="desc__header">Commandez tout votre matériel informatique en ligne sur EasyShopping Côte d’Ivoire</h5>
                            <p>Retrouvez sur notre plateforme de vente en ligne, le catalogue high-tech le plus complet. Pour votre entreprise ou vos activités personnelles, 
                                équipez-vous avec nos PC et ordinateurs bureaux, nos imprimantes avec leurs cartouches d'encre à des prix imbattables. 
                                Nos articles bénéficient en plus d’une garantie, ce qui vous met à l'abri de tout imprévu. En plus, nos ordinateurs sont de premiers choix.</p>

                            <h5 className="desc__header">HP, Toshiba, Dell, Acer, Lenovo, APC, D-Link : équipez vous avec les plus grandes marques</h5>
                            <p>EasyShopping Côte d’Ivoire, c’est la plus grande boutique en ligne et chez nous, vous retrouvez 
                                les meilleures marques de l’informatique. Elles vous donnent rendez-vous dans nos rayons avec des prix incroyables. Faites votre shopping dans notre catalogue informatique en choisissant des sacoches et autres accessoires également assortis à votre appareil. En plus, nos articles bénéficient d’une garantie constructeur. Quant à la livraison, ne vous en faites pas : 
                                un clic et nous sommes en bas de chez vous !</p>
                        </div>

                        </div>
         ) : (
       <div className="part__accessories">
          {items.map((item) => <div key={item._id}className='product__cards'>
                        <div className='product__cards__header'>
                        <p className='sales'>{item.title}</p>
                           <div className='product__cards__header__images'>
                           <Link to={`/detail/${item._id}`}><img src={`${process.env.PUBLIC_URL}/${item.img}`} alt="" /></Link>
                           </div>
                        </div>
                        <div className='product__cards__body'>
                                {/* <h5 className='product__title'>{article.title}</h5> */}
                                <p>{item.description}</p>
                               <p className='product__price'>{item.price} FCFA</p>
                               <div className='button--block'>
                               {/* <Link className='link__btn' to={`/detail/${article._id}`}><button className='btn__buy'>Acheter</button></Link> */}
                               <button className='btn__add'onClick={() => AddArticle(item)}>Ajouter au panier</button>
                                </div>  
                        </div>
                    </div> 
                        )}
                    
          </div>
         )} 
       </div>
          
          </div>
        <Footer/>
        </div>
    )
}
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { AddArticle, DeleteArticle } from '../Redux/actions';
import Footer from './Footer';
import Navbar from './Navbar';


export default function Commandes (){

    const carts = useSelector(state => state.cartReducer.carts);
    const [quantity,setQuantity]=useState(1);
    const [name,setName]=useState("");
    const [adresse,setAdresse]=useState("");
    const [numero,setTel]=useState("");
    const [price, setPrice] = useState(0);
    const navigate = useNavigate();
    let id = useParams().id;
    console.log(carts)

    const dispatch = useDispatch()
    
    const removeArticle = (id) =>{
        dispatch(DeleteArticle(id))
      }
    const handleName = (e) =>{
        setName(e.target.value)
    }
    const handleAdresse = (e) =>{
        setAdresse(e.target.value)
    }
    const handleTel = (e) =>{
        setTel(e.target.value)
    }
      const handleQuantity = (type) => {
        if (type === "dec") {
          quantity > 1 && setQuantity(quantity - 1);
        } else {
          setQuantity(quantity + 1);
        }
      };


    const increaseQty = (id)=>{
        if (quantity < 10){
            setQuantity(prevCount => prevCount + 1);
        }
    }

    const decreaseQty = (id) => {
        setQuantity((prevCount) => {
          let tempQty = prevCount - 1;
          if(tempQty < 1) tempQty = 1;
          return tempQty;
        })
      }

    //   useEffect(() => {
    //     setCart(JSON.parse(localStorage.setItem("cart"))) 
    //     }, [cart])

        // useEffect(() => {
        //     setCart(JSON.parse(localStorage.setItem("carts")))
        //     }, [cart])

          const handlePrice = ()=>{
                let total = 0;
                carts.map((cart)=>(total += quantity * cart.price))
                setPrice(parseInt(total));
            }
    
            useEffect(()=>{
                handlePrice();
            })
            
            const Paiement= (e) =>{
                e.preventDefault();
                const dataPayment = {
                  product_id: id,
                  quantity,
                  price,
                  name,
                  numero,
                  adresse
                }
                fetch('http://localhost:8080/orders',{
                      method:"POST",
                      headers :{'Content-Type': "application/json"},
                      body: JSON.stringify(dataPayment)
                  }).then((res)=>res.json())
                   .then((userData)=> {
                    localStorage.clear();
                    localStorage.setItem("user",JSON.stringify(userData));
                    navigate('/');
                    // notify("")    
                    }).catch(err => {console.log(err)});
              };


    return (
        <div>
            <Navbar/>
            <div className="container">
            <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Mon panier</li>
            </ol>
            </nav>
            <h2>Votre panier</h2>
            <div className="main--orders">
            <div className="main--orders--container">
                <div className="main--orders--container--left">
                { carts.length !== 0 ? carts.map((cart)=> <div key={id} className="cart--articles">
                            {/* const totalPrice = carts.price * quantity */}
                            
                                    <div className="cart--articles--descriptions">
                                        <div className="block--articles">
                                            <div className="articles">
                                                <img src={cart.img} alt="" />
                                            </div>
                        
                                            <div className="articles--details">
                                                <p className="names--articles">{cart.categorie}</p>
                                                <p className="names--articles">{cart.description}</p>
                                                <p className="quantity">{quantity} unité (s)</p>
                                                <p className="price--articles"> {(cart.price * quantity)} F CFA</p>
                                            </div>

                                        <div className="button-block">
                                            <div className="handle--quantity">
                                            <div className="minus" onClick={()=>decreaseQty(id)}><h5>-</h5></div>
                                            <div className="qty"><h5>{quantity}</h5></div>
                                            <div className="plus" onClick={()=>increaseQty(id)}><h5>+</h5></div>
                                        </div>
                                    </div>
                                    {/* <div className="button-block">
                                    <select class="form-select" aria-label="Default select example" value={quantity} onChange={handleQuantity}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        </select>
                                    </div> */}
                                        </div>
                                        <div className="btn--remove--article" onClick={removeArticle}>
                                        <i className="fa-solid fa-trash"></i>
                                    </div>
                                    </div>
    
                                </div>
                             ) :  <div className="cart--articles--container"> 
                                    <div className='cart--articles--container--content'>
                                    <h1>Votre panier est vide</h1>
                                    </div>
                             </div>
                             }

                </div>

                <div className="main--orders--container--right">
                      <h6 className='main--orders--container--right--header'>Résumé du panier</h6> 
                      <div className='main--orders--container--right--body'>
                            <div className='article__details__order'>
                                <div className='article__details__order__content'>
                                    <h6>Sous-total</h6>
                                    <p className="price">{price} FCFA</p>
                                </div>
                                {/* <div class="col-md-12">
                                <select class="form-select" aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                                </select>
                                </div> */}
                                {/* <div className='article__details__order__content'>
                                    <h5>Frais de livraison</h5>
                                    <p>5 000 FCFA</p>
                                </div> */}
                                {/* <div className='article__details__order__content'>
                                    <h5>Nº Coupon</h5>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                                        <label for="floatingInput">Nº coupon</label>
                                        </div>
                                </div> */}
                                {/* <div className='article__details__order__content'>
                                    <h5>Coupon</h5>
                                    <p>5 000 FCFA</p>
                                </div> */}
                            </div>
                            <div className='user__part'>
                                <h4>Vos coordonnés</h4>
                         <form class="row g-3 needs-validation" novalidate>
                                <div class="col-md-12">
                                    <label for="validationCustom01" class="form-label" onChange={handleName} value={name}>Noms complets</label>
                                    <input type="text" class="form-control" id="validationCustom01" required />
                                </div>

                                <div class="col-md-12">
                                    <label for="validationCustom03" class="form-label" onChange={handleAdresse} value={adresse}>Addresse</label>
                                    <input type="text" class="form-control" id="validationCustom03" required />
                                </div>

                                <div class="col-md-12">
                                    <label for="validationCustom03" class="form-label" onChange={handleTel} value={numero}>Nº Tel</label>
                                    <input type="text" class="form-control" id="validationCustom03" required />
                                </div>
                        </form>
                            </div>
                            <div className='payment__part'>
                            <h4>Nous acceptons :</h4>
                            <div className='payment__part__content'>
                                <img src="https://www3.easiware.fr/courir/Public/CRM/ILib_Image/R21/paiement.png" alt="" />
                            </div>
                            </div>
                            
                            <div className='payment__part'>
                            <h4>Option de paiement</h4>
                            <div className='payment__part__content'>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Cash à la livraison
                                </label>
                                </div>
                                <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                <label class="form-check-label" for="flexRadioDefault2">
                                    Paiement par Money
                                </label>
                                </div>
                            </div>
                            </div>
                      </div>
                </div>
        </div>     
    </div>
    <button className='btn__payment' type='submit' onClick={Paiement}>Procéder au paiement</button>  

        </div>
        <Footer/>
        </div>
    );
}
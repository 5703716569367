import { uid } from "uid"

const initialState = {
    categories: [],
    // products: [],
    newArrivages:[],
    newsProducts:[
        {
            id:9,
            title: 'Sweats',
            img:'https://mistertee.fr/sites/default/files/styles/mistertee_product_display_medium/public/hoodie.jpg',
            description:"Sweats Blanc",
            price:"1000",
            categories:"Sweats",
            genre:"Homme",
        },
        {
            id:10,
            title: 'Sweats',
            img:'https://www.kiabi.be/images/sweat-a-capuche-friends-bordeaux-femme-du-34-au-48-yt181_1_frb1.jpg',
            description:"Sweats Marron",
            price:"1000",
            categories:"Sweats",
            genre:"Homme",
        },
        {
            id:11,
            title: 'Sweats',
            img:'https://mistertee.fr/sites/default/files/styles/mistertee_product_display_medium/public/hoodie.jpg',
            description:"Sweats Blanc",
            price:"1000",
            categories:"Sweats",
            genre:"Homme",
        },
    ],
    Mens:[
          {
            id:13,
            title: 'T-shirts',
            img:'1.jpg',
            description:"T-shirts",
            price:"1000",
            categories:"T-shirts",
            genre:"Homme",
        },
        {
            id:14,
            title: 'T-shirts',
            img:'11.jpg',
            description:"T-shirts df collection",
            price:"1000",
            categories:"T-shirts",
            genre:"Homme",
        },
        {
            id:15,
            title: 'T-shirts',
            img:'6.jpg',
            description:"T-shirts df collection",
            price:"1000",
            categories:"T-shirts",
            genre:"Homme",
        },
        {
            id:16,
            title: 'T-shirts',
            img:'4.jpg',
            description:"T-shirts df collection",
            price:"1000",
            categories:"T-shirts",
            genre:"Homme",
        },
        {
            id:17,
            title: 'Polo',
            img:'46.JPG',
            description:"Polo df collection",
            price:"1000",
            categories:"Polo homme",
            genre:"Homme",
        },
        {
            id:18,
            title: 'Polo',
            img:'47.JPG',
            description:"Polo homme df collection",
            price:"1000",
            categories:"Polo",
            genre:"Homme",
        },
        {
            id:19,
            title: 'Polo',
            img:'49.JPG',
            description:"Polo homme df collection",
            price:"1000",
            categories:"Polo",
            genre:"Homme",
        },
        {
            id:20,
            title: 'Débardeur',
            img:'41.JPG',
            description:"Débardeur homme df collection",
            price:"1000",
            categories:"Débardeurs",
            genre:"Homme",
        },
    {
        id:21,
        title: 'Sweats',
        img:'52.JPG',
        description:"Sweats Blanc df collection",
        price:"1000",
        categories:"Sweats a capuche",
        genre:"Homme",
    },
    {
        id:22,
        title: 'Sweats',
        img:'53.JPG',
        description:"Sweats Blanc df collection",
        price:"1000",
        categories:"Sweats a capuche",
        genre:"Homme",
    },
    {
        id:23,
        title: 'Sweats',
        img:'68.JPG',
        description:"Sweats Blanc df collection",
        price:"1000",
        categories:"Sweats",
        genre:"Homme",
    },
    {
        id:24,
        title: 'Sweats',
        img:'69.JPG',
        description:"Sweats Marron df collection",
        price:"1000",
        categories:"Sweats",
        genre:"Homme",
    },
    {
        id:25,
        title: 'Shorts',
        img:'85.JPG',
        description:"Shorts Blanc df collection",
        price:"1000",
        categories:"Sweats Shorts",
        genre:"Homme",
    },
    {
        id:26,
        title: 'Shorts',
        img:'86.JPG',
        description:"Shorts Noir df collection ",
        price:"1000",
        categories:"Sweats Shorts",
        genre:"Homme",
    },
    {
        id:27,
        title: 'Shorts',
        img:'88.jpeg',
        description:"Jeans Shorts Blanc df collection",
        price:"1000",
        categories:"Jeans Shorts",
        genre:"Homme",
    },
    {
        id:28,
        title: 'Shorts',
        img:'89.jpeg',
        description:"Jeans Shorts Noir df collection ",
        price:"1000",
        categories:"Jeans Shorts",
        genre:"Homme",
    },
    {
        id:29,
        title: 'Jeans',
        img:'98.jpg',
        description:"Jeans",
        price:"1000",
        categories:"Jeans",
        genre:"Homme",
    },
    {
        id:30,
        title: 'Jeans',
        img:'99.png',
        description:"Jeans",
        price:"1000",
        categories:"Jeans",
        genre:"Homme",
    },
    {
        id:31,
        title: 'Jogging',
        img:'103.png',
        description:"Jogging",
        price:"1000",
        categories:"Jogging",
        genre:"Homme",
    },
    {
        id:32,
        title: 'Jogging',
        img:'101.jpg',
        description:"Jogging",
        price:"1000",
        categories:"Jogging",
        genre:"Homme",
    },
    {
        id:33,
        title: 'Jeans',
        img:'104.jpg',
        description:"Jeans Slim",
        price:"1000",
        categories:"Jeans Slim",
        genre:"Homme",
    },
    {
        id:34,
        title: 'Jeans',
        img:'105.jpg',
        description:"Jeans Slim",
        price:"1000",
        categories:"Jeans Slim",
        genre:"Homme",
    },
    {
        id:35,
        title: 'Baskets',
        img:'112.jpg',
        description:"Basket Blanche",
        price:"1000",
        categories:"Baskets",
        genre:"Homme",
    },
    {
        id:36,
        title: 'Baskets',
        img:'114.jpg',
        description:"Basket Blanche",
        price:"1000",
        categories:"Baskets",
        genre:"Homme",
    },
    {
        id:37,
        title: 'Scandales',
        img:'117.jpg',
        description:"Scandale Noir",
        price:"1000",
        categories:"Scandales",
        genre:"Homme",
    },
    {
        id:38,
        title: 'Scandales',
        img:'118.jpg',
        description:"Scandale Noir",
        price:"1000",
        categories:"Scandales",
        genre:"Homme",
    },
    {
        id:39,
        title: 'Mocassins',
        img:'121.jpg',
        description:"Mocassin",
        price:"1000",
        categories:"Mocassins",
        genre:"Homme",
    },
    {
        id:40,
        title: 'Mocassins',
        img:'122.jpg',
        description:"Mocassin Noir",
        price:"1000",
        categories:"Mocassins",
        genre:"Homme",
    },
    {
        id:41,
        title: 'Sacs',
        img:'135.jpg',
        description:"Bandoulières",
        price:"1000",
        categories:"Bandoulières",
        genre:"Homme",
    },
    {
        id:42,
        title: 'Sacs',
        img:'136.jpg',
        description:"Bandoulières",
        price:"1000",
        categories:"Bandoulières",
        genre:"Homme",
    },
    {
        id:43,
        title: 'Sacs',
        img:'142.jpg',
        description:"Sacs à dos",
        price:"1000",
        categories:"Sacs à dos",
        genre:"Homme",
    },
    {
        id:44,
        title: 'Sacs',
        img:'145.jpg',
        description:"Sacs à dos",
        price:"1000",
        categories:"Sacs à dos",
        genre:"Homme",
    },
    {
        id:51,
        title: 'Bracelets',
        img:'156.jpg',
        description:"Bracelets Hugo Boss",
        price:"1000",
        categories:"Bracelets",
        genre:"Homme",
    },
    {
        id:52,
        title: 'Bracelets',
        img:'157.PNG',
        description:"Bracelets",
        price:"1000",
        categories:"Bracelets",
        genre:"Homme",
    },
    {
        id:53,
        title: 'Bijoux',
        img:'159.jpg',
        description:"Bague",
        price:"1000",
        categories:"Bijoux",
        genre:"Homme",
    },
    {
      id:54,
      title: 'Bijoux',
      img:'160.JPG',
      description:"Bague",
      price:"1000",
      categories:"Bijoux",
      genre:"Homme",
  },
    {
        id:55,
        title: 'Lunettes',
        img:'166.JPG',
        description:"Lunettes",
        price:"1000",
        categories:"Lunettes",
        genre:"Homme",
    },
    {
      id:56,
      title: 'Lunettes',
      img:'167.JPG',
      description:"Lunettes",
      price:"1000",
      categories:"Lunettes",
      genre:"Homme",
  },
    {
        id:57,
        title: 'Chaussettes',
        img:'169.JPG',
        description:"Chaussettes noires",
        price:"1000",
        categories:"Chaussettes",
        genre:"Homme",
    },
    {
        id:58,
        title: 'Chaussettes',
        img:'170.jpg',
        description:"Chaussettes nike ",
        price:"1000",
        categories:"Chaussettes",
        genre:"Homme",
    },
    {
        id:59,
        title: 'Montres',
        img:'o.jpeg',
        description:"Montre Omega",
        price:"1000",
        categories:"Montres",
        genre:"Homme",
    },
    {
        id:60,
        title: 'Montres',
        img:'omega.png',
        description:"Montre Omega",
        price:"1000",
        categories:"Montres",
        genre:"Homme",
    },
    {
      id:61,
      title: 'Montres',
      img:'n.jpeg',
      description:"Montre digitale",
      price:"1000",
      categories:"Montres",
      genre:"Homme",
  },
    ],
    Womens:[
        {
          id:13,
          title: 'T-shirts',
          img:'1.jpg',
          description:"T-shirts",
          price:"1000",
          categories:"T-shirts",
          genre:"Homme",
      },
      {
          id:14,
          title: 'T-shirts',
          img:'11.jpg',
          description:"T-shirts df collection",
          price:"1000",
          categories:"T-shirts",
          genre:"Homme",
      },
      {
          id:15,
          title: 'T-shirts',
          img:'6.jpg',
          description:"T-shirts df collection",
          price:"1000",
          categories:"T-shirts",
          genre:"Homme",
      },
      {
          id:16,
          title: 'T-shirts',
          img:'4.jpg',
          description:"T-shirts df collection",
          price:"1000",
          categories:"T-shirts",
          genre:"Homme",
      },
      {
          id:17,
          title: 'Polo',
          img:'46.JPG',
          description:"Polo df collection",
          price:"1000",
          categories:"Polo homme",
          genre:"Homme",
      },
      {
          id:18,
          title: 'Polo',
          img:'47.JPG',
          description:"Polo homme df collection",
          price:"1000",
          categories:"Polo",
          genre:"Homme",
      },
      {
          id:19,
          title: 'Polo',
          img:'49.JPG',
          description:"Polo homme df collection",
          price:"1000",
          categories:"Polo",
          genre:"Homme",
      },
      {
          id:20,
          title: 'Débardeur',
          img:'41.JPG',
          description:"Débardeur homme df collection",
          price:"1000",
          categories:"Débardeurs",
          genre:"Homme",
      },
  {
      id:21,
      title: 'Sweats',
      img:'52.JPG',
      description:"Sweats Blanc df collection",
      price:"1000",
      categories:"Sweats a capuche",
      genre:"Homme",
  },
  {
      id:22,
      title: 'Sweats',
      img:'53.JPG',
      description:"Sweats Blanc df collection",
      price:"1000",
      categories:"Sweats a capuche",
      genre:"Homme",
  },
  {
      id:23,
      title: 'Sweats',
      img:'68.JPG',
      description:"Sweats Blanc df collection",
      price:"1000",
      categories:"Sweats",
      genre:"Homme",
  },
  {
      id:24,
      title: 'Sweats',
      img:'69.JPG',
      description:"Sweats Marron df collection",
      price:"1000",
      categories:"Sweats",
      genre:"Homme",
  },
  {
      id:25,
      title: 'Shorts',
      img:'85.JPG',
      description:"Shorts Blanc df collection",
      price:"1000",
      categories:"Sweats Shorts",
      genre:"Homme",
  },
  {
      id:26,
      title: 'Shorts',
      img:'86.JPG',
      description:"Shorts Noir df collection ",
      price:"1000",
      categories:"Sweats Shorts",
      genre:"Homme",
  },
  {
      id:27,
      title: 'Shorts',
      img:'88.jpeg',
      description:"Jeans Shorts Blanc df collection",
      price:"1000",
      categories:"Jeans Shorts",
      genre:"Homme",
  },
  {
      id:28,
      title: 'Shorts',
      img:'89.jpeg',
      description:"Jeans Shorts Noir df collection ",
      price:"1000",
      categories:"Jeans Shorts",
      genre:"Homme",
  },
  {
      id:29,
      title: 'Jeans',
      img:'98.jpg',
      description:"Jeans",
      price:"1000",
      categories:"Jeans",
      genre:"Homme",
  },
  {
      id:30,
      title: 'Jeans',
      img:'99.png',
      description:"Jeans",
      price:"1000",
      categories:"Jeans",
      genre:"Homme",
  },
  {
      id:31,
      title: 'Jogging',
      img:'103.png',
      description:"Jogging",
      price:"1000",
      categories:"Jogging",
      genre:"Homme",
  },
  {
      id:32,
      title: 'Jogging',
      img:'101.jpg',
      description:"Jogging",
      price:"1000",
      categories:"Jogging",
      genre:"Homme",
  },
  {
      id:33,
      title: 'Jeans',
      img:'104.jpg',
      description:"Jeans Slim",
      price:"1000",
      categories:"Jeans Slim",
      genre:"Homme",
  },
  {
      id:34,
      title: 'Jeans',
      img:'105.jpg',
      description:"Jeans Slim",
      price:"1000",
      categories:"Jeans Slim",
      genre:"Homme",
  },
  {
      id:35,
      title: 'Baskets',
      img:'112.jpg',
      description:"Basket Blanche",
      price:"1000",
      categories:"Baskets",
      genre:"Homme",
  },
  {
      id:36,
      title: 'Baskets',
      img:'114.jpg',
      description:"Basket Blanche",
      price:"1000",
      categories:"Baskets",
      genre:"Homme",
  },
  {
      id:37,
      title: 'Scandales',
      img:'117.jpg',
      description:"Scandale Noir",
      price:"1000",
      categories:"Scandales",
      genre:"Homme",
  },
  {
      id:38,
      title: 'Scandales',
      img:'118.jpg',
      description:"Scandale Noir",
      price:"1000",
      categories:"Scandales",
      genre:"Homme",
  },
  {
      id:39,
      title: 'Mocassins',
      img:'121.jpg',
      description:"Mocassin",
      price:"1000",
      categories:"Mocassins",
      genre:"Homme",
  },
  {
      id:40,
      title: 'Mocassins',
      img:'122.jpg',
      description:"Mocassin Noir",
      price:"1000",
      categories:"Mocassins",
      genre:"Homme",
  },
  {
      id:41,
      title: 'Sacs',
      img:'135.jpg',
      description:"Bandoulières",
      price:"1000",
      categories:"Bandoulières",
      genre:"Homme",
  },
  {
      id:42,
      title: 'Sacs',
      img:'136.jpg',
      description:"Bandoulières",
      price:"1000",
      categories:"Bandoulières",
      genre:"Homme",
  },
  {
      id:43,
      title: 'Sacs',
      img:'142.jpg',
      description:"Sacs à dos",
      price:"1000",
      categories:"Sacs à dos",
      genre:"Homme",
  },
  {
      id:44,
      title: 'Sacs',
      img:'145.jpg',
      description:"Sacs à dos",
      price:"1000",
      categories:"Sacs à dos",
      genre:"Homme",
  },
    ],
    Babys:[
        {
          id:13,
          title: 'T-shirts',
          img:'1.jpg',
          description:"T-shirts",
          price:"1000",
          categories:"T-shirts",
          genre:"Homme",
      },
      {
          id:14,
          title: 'T-shirts',
          img:'11.jpg',
          description:"T-shirts df collection",
          price:"1000",
          categories:"T-shirts",
          genre:"Homme",
      },
      {
          id:15,
          title: 'T-shirts',
          img:'6.jpg',
          description:"T-shirts df collection",
          price:"1000",
          categories:"T-shirts",
          genre:"Homme",
      },
      {
          id:16,
          title: 'T-shirts',
          img:'4.jpg',
          description:"T-shirts df collection",
          price:"1000",
          categories:"T-shirts",
          genre:"Homme",
      },
      {
          id:17,
          title: 'Polo',
          img:'46.JPG',
          description:"Polo df collection",
          price:"1000",
          categories:"Polo homme",
          genre:"Homme",
      },
      {
          id:18,
          title: 'Polo',
          img:'47.JPG',
          description:"Polo homme df collection",
          price:"1000",
          categories:"Polo",
          genre:"Homme",
      },
      {
          id:19,
          title: 'Polo',
          img:'49.JPG',
          description:"Polo homme df collection",
          price:"1000",
          categories:"Polo",
          genre:"Homme",
      },
      {
          id:20,
          title: 'Débardeur',
          img:'41.JPG',
          description:"Débardeur homme df collection",
          price:"1000",
          categories:"Débardeurs",
          genre:"Homme",
      },
  {
      id:21,
      title: 'Sweats',
      img:'52.JPG',
      description:"Sweats Blanc df collection",
      price:"1000",
      categories:"Sweats a capuche",
      genre:"Homme",
  },
  {
      id:22,
      title: 'Sweats',
      img:'53.JPG',
      description:"Sweats Blanc df collection",
      price:"1000",
      categories:"Sweats a capuche",
      genre:"Homme",
  },
  {
      id:23,
      title: 'Sweats',
      img:'68.JPG',
      description:"Sweats Blanc df collection",
      price:"1000",
      categories:"Sweats",
      genre:"Homme",
  },
  {
      id:24,
      title: 'Sweats',
      img:'69.JPG',
      description:"Sweats Marron df collection",
      price:"1000",
      categories:"Sweats",
      genre:"Homme",
  },
  {
      id:25,
      title: 'Shorts',
      img:'85.JPG',
      description:"Shorts Blanc df collection",
      price:"1000",
      categories:"Sweats Shorts",
      genre:"Homme",
  },
  {
      id:26,
      title: 'Shorts',
      img:'86.JPG',
      description:"Shorts Noir df collection ",
      price:"1000",
      categories:"Sweats Shorts",
      genre:"Homme",
  },
  {
      id:27,
      title: 'Shorts',
      img:'88.jpeg',
      description:"Jeans Shorts Blanc df collection",
      price:"1000",
      categories:"Jeans Shorts",
      genre:"Homme",
  },
  {
      id:28,
      title: 'Shorts',
      img:'89.jpeg',
      description:"Jeans Shorts Noir df collection ",
      price:"1000",
      categories:"Jeans Shorts",
      genre:"Homme",
  },
  {
      id:29,
      title: 'Jeans',
      img:'98.jpg',
      description:"Jeans",
      price:"1000",
      categories:"Jeans",
      genre:"Homme",
  },
  {
      id:30,
      title: 'Jeans',
      img:'99.png',
      description:"Jeans",
      price:"1000",
      categories:"Jeans",
      genre:"Homme",
  },
  {
      id:31,
      title: 'Jogging',
      img:'103.png',
      description:"Jogging",
      price:"1000",
      categories:"Jogging",
      genre:"Homme",
  },
  {
      id:32,
      title: 'Jogging',
      img:'101.jpg',
      description:"Jogging",
      price:"1000",
      categories:"Jogging",
      genre:"Homme",
  },
  {
      id:33,
      title: 'Jeans',
      img:'104.jpg',
      description:"Jeans Slim",
      price:"1000",
      categories:"Jeans Slim",
      genre:"Homme",
  },
  {
      id:34,
      title: 'Jeans',
      img:'105.jpg',
      description:"Jeans Slim",
      price:"1000",
      categories:"Jeans Slim",
      genre:"Homme",
  },
  {
      id:35,
      title: 'Baskets',
      img:'112.jpg',
      description:"Basket Blanche",
      price:"1000",
      categories:"Baskets",
      genre:"Homme",
  },
  {
      id:36,
      title: 'Baskets',
      img:'114.jpg',
      description:"Basket Blanche",
      price:"1000",
      categories:"Baskets",
      genre:"Homme",
  },
  {
      id:37,
      title: 'Scandales',
      img:'117.jpg',
      description:"Scandale Noir",
      price:"1000",
      categories:"Scandales",
      genre:"Homme",
  },
  {
      id:38,
      title: 'Scandales',
      img:'118.jpg',
      description:"Scandale Noir",
      price:"1000",
      categories:"Scandales",
      genre:"Homme",
  },
  {
      id:39,
      title: 'Mocassins',
      img:'121.jpg',
      description:"Mocassin",
      price:"1000",
      categories:"Mocassins",
      genre:"Homme",
  },
  {
      id:40,
      title: 'Mocassins',
      img:'122.jpg',
      description:"Mocassin Noir",
      price:"1000",
      categories:"Mocassins",
      genre:"Homme",
  },
  {
      id:41,
      title: 'Sacs',
      img:'135.jpg',
      description:"Bandoulières",
      price:"1000",
      categories:"Bandoulières",
      genre:"Homme",
  },
  {
      id:42,
      title: 'Sacs',
      img:'136.jpg',
      description:"Bandoulières",
      price:"1000",
      categories:"Bandoulières",
      genre:"Homme",
  },
  {
      id:43,
      title: 'Sacs',
      img:'142.jpg',
      description:"Sacs à dos",
      price:"1000",
      categories:"Sacs à dos",
      genre:"Homme",
  },
  {
      id:44,
      title: 'Sacs',
      img:'145.jpg',
      description:"Sacs à dos",
      price:"1000",
      categories:"Sacs à dos",
      genre:"Homme",
  },
    ],
    Beautys:[
        {
          id:45,
          title: 'Maquillage',
          img:'fond 2.jpeg',
          description:"Fond de teint",
          price:"1000",
          categories:"Visage",
          genre:"Femme",
      },
      {
          id:46,
          title: 'Maquillage',
          img:'fond.jpeg',
          description:"Fond de teint",
          price:"1000",
          categories:"Visage",
          genre:"Femme",
      },
      {
          id:47,
          title: 'Parfums',
          img:'di.png',
          description:"Parfums Homme",
          price:"1000",
          categories:"Parfums homme",
          genre:"Homme",
      },
      {
          id:48,
          title: 'Parfums',
          img:'parfWomen.jpeg',
          description:"Parfums femme",
          price:"1000",
          categories:"Parfums femme",
          genre:"Femme",
      },
      {
        id:49,
        title: 'Déo',
        img:'deo.jpeg',
        description:"Deo Nivea Men",
        price:"1000",
        categories:"Deo",
        genre:"Homme",
    },
    {
        id:50,
        title: 'Préservatifs',
        img:'pre.jpeg',
        description:"Préservatifs infinity",
        price:"1000",
        categories:"Préservatifs",
        genre:"Homme",
    },
    {
        id:51,
        title: 'Lubrifiants',
        img:'lub.jpeg',
        description:"Lubrifiants",
        price:"1000",
        categories:"Lubrifiants",
        genre:"Homme",
    },
    {
        id:52,
        title: 'Soins',
        img:'peau.jpeg',
        description:"Lait de corps Nivea",
        price:"1000",
        categories:"Peau",
        genre:"Homme",
    },
    {
        id:53,
        title: 'Soins',
        img:'chev.jpeg',
        description:"lait de cheveux GBH",
        price:"1000",
        categories:"Cheveux",
        genre:"Homme",
    },
    {
        id:54,
        title: 'Epilation',
        img:'epi.jpeg',
        description:"Machine à Epilation",
        price:"1000",
        categories:"Epi",
        genre:"Homme",
    },
    {
        id:55,
        title: 'Soins',
        img:'rouge.jpeg',
        description:"Rouge à Lèvres",
        price:"1000",
        categories:"Rouge à Lèvres",
        genre:"Homme",
    },
    {
        id:56,
        title: 'Soins',
        img:'vernis.jpeg',
        description:"Vernis a ongles ",
        price:"1000",
        categories:"Vernis",
        genre:"Homme",
    },
    {
        id:57,
        title: 'Soins',
        img:'levre.jpeg',
        description:"Rouge à Lèvres",
        price:"1000",
        categories:"Lèvre",
        genre:"Femme",
    },
     
    ],
    Houses:[
        {
            id:85,
            title: 'Cuisine',
            img:'ustens.jpeg',
            description:"Ustensiles de cuisine",
            price:"1000",
            categories:"Ustensile",
        },
        {
            id:86,
            title: 'Cuisine',
            img:'stock.jpeg',
            description:"Stockage ustensils",
            price:"1000",
            categories:"Stockage",
        },
        {
            id:87,
            title: 'Electromenager',
            img:'Bou.jpeg',
            description:"Bouilloire electrique 10L",
            price:"1000",
            categories:"Bouilloires",
        },
        {
            id:88,
            title: 'Electromenager',
            img:'cafe.jpeg',
            description:"Café expresso Nasco electrique ",
            price:"1000",
            categories:"Café",
        },
        {
            id:89,
            title: 'Electromenager',
            img:'the.jpeg',
            description:"Thé expresso Ilux electrique ",
            price:"1000",
            categories:"Café",
        },
        {
            id:90,
            title: 'Electro',
            img:'blendeur.jpeg',
            description:"Blendeur Binatone",
            price:"1000",
            categories:"Blenders",
        },
        {
            id:91,
            title: 'Electro',
            img:'friteuse.jpeg',
            description:"Friteuses",
            price:"1000",
            categories:"Friteuses",
        },
        {
            id:92,
            title: 'Electro',
            img:'fer.jpeg',
            description:"Fer à repasser",
            price:"1000",
            categories:"Fer à repasser",
        },
        {
            id:93,
            title: 'Electro',
            img:'micro.jpeg',
            description:"Micro-onde Hisense Numerique",
            price:"1000",
            categories:"Micro-ondes",
        },
        {
            id:94,
            title: 'Electro',
            img:'refrigerateur.jpeg',
            description:"Réfrigerateur Smart Technology",
            price:"1000",
            categories:"Réfrigerateurs",
        },
        {
            id:95,
            title: 'Electro',
            img:'congelateur.jpeg',
            description:"Congélateurs",
            price:"1000",
            categories:"Congélateurs",
        },
        {
            id:96,
            title: 'Electro',
            img:'laveuse.jpeg',
            description:"Machine à Laver Smart",
            price:"1000",
            categories:"Laveuses",
        },
        {
            id:97,
            title: 'Electro',
            img:'gazi.jpeg',
            description:"Gazinières Nasco",
            price:"1000",
            categories:"Gazinières",
        },
        {
            id:98,
            title: 'Maison',
            img:'lampe.jpeg',
            description:"Lampe multifonction",
            price:"1000",
            categories:"Lampes",
        },
        {
            id:99,
            title: 'Maison',
            img:'deco.jpeg',
            description:"Deco Murale",
            price:"1000",
            categories:"Décoration",
        },
        {
            id:100,
            title: 'Maison',
            img:'literie.jpeg',
            description:"Cousin 2 lots",
            price:"1000",
            categories:"Literie",
        },
        {
            id:101,
            title: 'Maison',
            img:'salle.jpeg',
            description:"Ensemble salle de bain",
            price:"1000",
            categories:"Salle de bain",
        },
        {
            id:102,
            title: 'Air',
            img:'climatiseurs.jpeg',
            description:"Climatiseur Nasco 2ch",
            price:"1000",
            categories:"Climatiseurs",
        },
        {
            id:103,
            title: 'Air',
            img:'ventilateurs.jpeg',
            description:"Ventilateur Binatone",
            price:"1000",
            categories:"Ventilateurs",
        },
        {
            id:104,
            title: 'Jardin',
            img:'eclairage.jpeg',
            description:"Éclairage Mural",
            price:"1000",
            categories:"Éclairage",
        },
        {
            id:105,
            title: 'Jardin',
            img:'grillade.jpeg',
            description:"Grillades Barbecue",
            price:"1000",
            categories:"Grillades",
        },
        {
            id:106,
            title: 'Maison',
            img:'meubles.jpeg',
            description:"Meuble",
            price:"1000",
            categories:"Meubles",
        },
        {
            id:107,
            title: 'Bricolage',
            img:'outils.jpeg',
            description:"Outils tournevis complets",
            price:"1000",
            categories:"Outils à main",
        },
        {
            id:108,
            title: 'Bricolage',
            img:'ampoules.jpeg',
            description:"Ampoules 30W",
            price:"1000",
            categories:"Ampoules",
        },
        {
            id:109,
            title: 'Bricolage',
            img:'multi.jpeg',
            description:"Multiprise",
            price:"1000",
            categories:"Electricité",
        },
        {
            id:110,
            title: 'Bricolage',
            img:'pein.jpeg',
            description:"Meuble",
            price:"1000",
            categories:"Peintures",
        },


          ],
    Smartphones:[
        {
            id:58,
            title: 'Smartphones',
            img:'iph14.jpeg',
            description:"Iphone 14 256GB",
            price:"1000",
            categories:"Iphone",
        },
        {
            id:59,
            title: 'Smartphones',
            img:'s23.jpeg',
            description:"Samsung s23 8/256GB",
            price:"1000",
            categories:"Android",
        },
        {
            id:60,
            title: 'Smartphones',
            img:'h50.jpeg',
            description:"Huawei P50 8/256GB",
            price:"1000",
            categories:"Android",
        },
        {
            id:61,
            title: 'Smartphones',
            img:'x12T.jpeg',
            description:"Xiaomi 12T 8/256GB",
            price:"1000",
            categories:"Android",
        },
        {
            id:62,
            title: 'Smartphones',
            img:'in8.jpeg',
            description:"Infinix Hot 30",
            price:"1000",
            categories:"Android",
        },
        {
            id:63,
            title: 'Chargeurs',
            img:'chargeurI.jpeg',
            description:"Chargeur Iphone 14 256GB",
            price:"1000",
            categories:"Chargeurs",
        },
        {
            id:64,
            title: 'Chargeurs',
            img:'chamSa.jpeg',
            description:"Chargeur Samsung s23 8/256GB",
            price:"1000",
            categories:"Chargeurs",
        },
        {
            id:65,
            title: 'Chargeurs',
            img:'chaHua.jpeg',
            description:"Chargeur Huawei P50 8/256GB",
            price:"1000",
            categories:"Chargeurs",
        },
        {
            id:66,
            title: 'Chargeurs',
            img:'chaXia.jpeg',
            description:"Chargeur Xiaomi 12T 8/256GB",
            price:"1000",
            categories:"Chargeurs",
        },
        {
            id:67,
            title: 'Chargeurs',
            img:'chaInf.jpeg',
            description:"Chargeur Infinix Hot 30",
            price:"1000",
            categories:"Chargeurs",
        },
        {
            id:68,
            title: 'Telephones',
            img:'basi.jpeg',
            description:"Itel 12T",
            price:"1000",
            categories:"Basiques",
        },
        {
            id:69,
            title: 'Coques',
            img:'coq2.jpeg',
            description:"Coque samsung s22",
            price:"1000",
            categories:"Coques",
        },
        {
            id:70,
            title: 'Coques',
            img:'coqu.jpeg',
            description:"Coque iphone 14 pro",
            price:"1000",
            categories:"Coques",
        },
        {
            id:71,
            title: 'Chargeurs',
            img:'chaInf.jpeg',
            description:"Infinix Hot 30",
            price:"1000",
            categories:"Chargeur Infinix",
        },
        {
            id:72,
            title: 'Protections',
            img:'anti.jpeg',
            description:"Protections ecrans Huawei p50",
            price:"1000",
            categories:"Protections",
        },
        {
            id:73,
            title: 'Protections',
            img:'anti 2.jpeg',
            description:"Protections ecrans Iphone 14",
            price:"1000",
            categories:"Protections",
        },
        {
            id:74,
            title: 'Power banks',
            img:'Po.jpeg',
            description:"Power banks 10000mAh",
            price:"1000",
            categories:"Power banks",
        },
        {
            id:75,
            title: 'Power banks',
            img:'Power.jpeg',
            description:"Power banks 5000mAh",
            price:"1000",
            categories:"Power banks",
        },
        {
            id:76,
            title: 'Accessoires',
            img:'kit.jpeg',
            description:"Accessoires bluetooth",
            price:"1000",
            categories:"Accessoires bluetooth",
        },
        {
            id:77,
            title: 'Montres',
            img:'montre.jpeg',
            description:"Montres connectées",
            price:"1000",
            categories:"Montres connectées",
        },
        {
            id:78,
            title: 'Montres',
            img:'Montres.jpeg',
            description:"Montres connectées",
            price:"1000",
            categories:"Montres connectées",
        },
        {
            id:79,
            title: 'Casques',
            img:'cas.jpeg',
            description:"Casques Bluetooth JBL",
            price:"1000",
            categories:"Casques",
        },
        {
            id:80,
            title: 'Écouteurs',
            img:'ecou.jpeg',
            description:"Samsung galaxy buds pro",
            price:"1000",
            categories:"Écouteurs",
        },
        {
            id:81,
            title: 'Écouteurs',
            img:'ec.jpeg',
            description:"Air pods pro",
            price:"1000",
            categories:"Écouteurs",
        },
        {
            id:82,
            title: 'Écouteurs',
            img:'ec1.jpeg',
            description:"Écouteurs",
            price:"1000",
            categories:"Écouteurs",
        },
        {
        id:83,
        title: 'Tablettes',
        img:'ipad.jpeg',
        description:"Ipad pro 2022",
        price:"1000",
        categories:"Tablettes Ipad",
    },
    {
        id:84,
        title: 'Tablettes',
        img:'tab.jpeg',
        description:"Écouteurs",
        price:"1000",
        categories:"Tablettes Samsung",
    }
    ],
    Informatiques:[
        {
            id:111,
            title: 'Ordinateurs',
            img:'IMG_3629.jpg',
            description:"HP ",
            price:"1000",
            categories:"Ordinateurs",
        },
        {
            id:112,
            title: 'Ordinateurs',
            img:'IMG_3630.JPG',
            description:"Dell XPS 13 ",
            price:"1000",
            categories:"Ordinateurs",
        },
        {
            id:113,
            title: 'Ordinateurs',
            img:'IMG_3631.jpg',
            description:"Asus zenbook ",
            price:"1000",
            categories:"Ordinateurs",
        },
        {
            id:114,
            title: 'Ordinateurs',
            img:'IMG_3633.jpg',
            description:"Ordinateur Bureau HP ",
            price:"1000",
            categories:"Ordinateurs de bureau",
        },
        {
            id:115,
            title: 'Écran',
            img:'IMG_3634.JPG',
            description:"Imac 2015 27 pouces ",
            price:"1000",
            categories:"Écrans PC",
        },
        {
            id:116,
            title: 'Ordinateurs',
            img:'IMG_3632.jpg',
            description:"MacBook Pro 13 ",
            price:"1000",
            categories:"MacBook",
        },
        {
            id:117,
            title: 'Claviers',
            img:'IMG_3636.JPG',
            description:"Claviers + Souris Logitech",
            price:"1000",
            categories:"Claviers",
        },
        {
            id:118,
            title: 'Écran',
            img:'IMG_3635.JPG',
            description:"Ordinateur Bureau HP ",
            price:"1000",
            categories:"Écrans PC",
        },
        {
            id:119,
            title: 'Audio',
            img:'IMG_3637.JPG',
            description:"Audios ",
            price:"1000",
            categories:"Audio",
        },
        {
            id:120,
            title: 'Sacs',
            img:'IMG_3638.jpg',
            description:"Sacs ordinateur HP 15 pouces ",
            price:"1000",
            categories:"Sacs",
        },
        {
            id:121,
            title: 'Chargeurs',
            img:'IMG_3639.JPG',
            description:"Chargeurs MacBook",
            price:"1000",
            categories:"Chargeurs",
        },
        {
            id:122,
            title: 'Chargeurs',
            img:'IMG_3640.JPG',
            description:"Chargeurs HP",
            price:"1000",
            categories:"Chargeurs",
        },
        {
            id:123,
            title: 'Composantes',
            img:'IMG_3644.JPG',
            description:"Audios ",
            price:"1000",
            categories:"Composantes",
        },
        {
            id:124,
            title: 'Adaptateurs',
            img:'IMG_3641.jpg',
            description:"Adaptateurs Energiner 4 in 1",
            price:"1000",
            categories:"Adaptateurs",
        },
        {
            id:125,
            title: 'Adaptateurs',
            img:'IMG_3642.jpg',
            description:"Adaptateurs LEBAIQI 5 in 1",
            price:"1000",
            categories:"Adaptateurs",
        },
        {
            id:126,
            title: 'Adaptateurs',
            img:'IMG_3643.jpg',
            description:"Adaptateurs ",
            price:"1000",
            categories:"Adaptateurs",
        },
        {
            id:127,
            title: 'Imprimantes',
            img:'IMG_3645.JPG',
            description:"Imprimantes HP",
            price:"1000",
            categories:"Imprimantes",
        },
        {
            id:128,
            title: 'Imprimantes',
            img:'IMG_3646.JPG',
            description:"Imprimantes & Scanners Canon",
            price:"1000",
            categories:"Imprimantes",
        },
        {
            id:129,
            title: 'Encres',
            img:'IMG_3647.JPG',
            description:"Imprimantes HP 61 Noir",
            price:"1000",
            categories:"Encres",
        },
        {
            id:130,
            title: 'Encres',
            img:'IMG_3648.JPG',
            description:"Imprimantes HP 650 ",
            price:"1000",
            categories:"Encres",
        },
        {
            id:131,
            title: 'Clé USB',
            img:'IMG_3649.jpg',
            description:"Clé USB Toshiba 32GB",
            price:"1000",
            categories:"Clés USB",
        },
        {
            id:132,
            title: 'Clé USB',
            img:'IMG_3651.jpg',
            description:"Clé USB San Disk 32GB",
            price:"1000",
            categories:"Clés USB",
        },
        {
            id:133,
            title: 'Disques durs',
            img:'IMG_3652.JPG',
            description:"Disques durs 1 TB",
            price:"1000",
            categories:"Disques durs",
        },
        {
            id:134,
            title: 'Disques durs',
            img:'IMG_3652.JPG',
            description:"Disques durs 500 GB",
            price:"1000",
            categories:"Disques durs",
        },
        {
            id:135,
            title: 'Mémoire',
            img:'IMG_3655.JPG',
            description:"Cartes Mémoires San Disk 128 GB",
            price:"1000",
            categories:"Cartes",
        },
        {
            id:136,
            title: 'Mémoire',
            img:'IMG_3656.JPG',
            description:"Cartes Mémoires San Disk 64 GB",
            price:"1000",
            categories:"Cartes",
        },
        {
            id:137,
            title: 'SSD',
            img:'IMG_3654.JPG',
            description:"Disques durs SSD 1TB",
            price:"1000",
            categories:"SSD",
        },
        {
            id:138,
            title: 'Antivirus',
            img:'IMG_3658.jpg',
            description:"Kapersky",
            price:"1000",
            categories:"Antivirus",
        },
        {
            id:139,
            title: 'Licences',
            img:'IMG_3657.jpg',
            description:"Licences Window 10",
            price:"1000",
            categories:"Licences",
        },
    ],
    Supermarkets:[
        {
            id:140,
            title: 'Eau',
            img:'eau.jpeg',
            description:"Eau 1.5L Celeste ",
            price:"1000",
            categories:"Eau",
        },
        {
            id:141,
            title: 'Céréales',
            img:'cereales.jpeg',
            description:"Céréales Lion ",
            price:"1000",
            categories:"Céréales",
        },
        {
            id:142,
            title: 'Alimentaires',
            img:'riz.jpeg',
            description:"Rizières 25kg",
            price:"1000",
            categories:"Riz",
        },
        {
            id:143,
            title: 'Alimentaires',
            img:'huile.jpeg',
            description:"Huile Dinor",
            price:"1000",
            categories:"Huiles",
        },
        {
            id:144,
            title: 'Soins',
            img:'soin.jpeg',
            description:"Soins de peau",
            price:"1000",
            categories:"Soins de peau",
        },
        {
            id:145,
            title: 'Entretien',
            img:'less.jpeg',
            description:"Omo 450g",
            price:"1000",
            categories:"Lessive",
        },
        {
            id:146,
            title: 'Entretien',
            img:'net.jpeg',
            description:"Savon BF",
            price:"1000",
            categories:"Nettoyage",
        },
        {
            id:147,
            title: 'Entretien',
            img:'vaiss.jpeg',
            description:"Savon liquide",
            price:"1000",
            categories:"Vaisselle",
        },
        {
            id:148,
            title: 'Entretien',
            img:'hygie.jpeg',
            description:"Papiers Hygiéniques",
            price:"1000",
            categories:"Papiers",
        },
        {
            id:149,
            title: 'Sucrerie',
            img:'sucre.jpeg',
            description:"Sucre Princesse 1kg",
            price:"1000",
            categories:"Sucres",
        },
        {
            id:150,
            title: 'Boissons',
            img:'boisson.webp',
            description:"Boissons",
            price:"1000",
            categories:"Boissons gazeuses",
        },
        {
            id:151,
            title: 'Boissons',
            img:'biere.jpeg',
            description:"Biere Heineken",
            price:"1000",
            categories:"Bières",
        },
        {
            id:152,
            title: 'Boissons',
            img:'vin.jpeg',
            description:"Biere Heineken",
            price:"1000",
            categories:"Vins",
        },
        {
            id:153,
            title: 'Boissons',
            img:'ju.jpeg',
            description:"Jus Fresh",
            price:"1000",
            categories:"Jus",
        },
        {
            id:154,
            title: 'Entretien',
            img:'bri.jpeg',
            description:"Briquets Allumage",
            price:"1000",
            categories:"Briquets",
        },
        {
            id:155,
            title: 'Soins',
            img:'couches.jpeg',
            description:"Couches bébé",
            price:"1000",
            categories:"Couches Bébé",
        },
        {
            id:156,
            title: 'Entretiens',
            img:'ord.jpeg',
            description:"Sacs à ordures",
            price:"1000",
            categories:"Sacs",
        },
        {
            id:157,
            title: 'Alimentaires',
            img:'lait.png',
            description:"Lait nourrissons",
            price:"1000",
            categories:"Alimentations bébé",
        },
        {
            id:157,
            title: 'Alimentaires',
            img:'pate.png',
            description:"Pâte Panzani",
            price:"1000",
            categories:"Pâtes",
        },
        
    ],
    Accesoires:[
        {
          id:51,
          title: 'Bracelets',
          img:'156.jpg',
          description:"Bracelets Hugo Boss",
          price:"1000",
          categories:"Bracelets",
          genre:"Homme",
      },
      {
          id:52,
          title: 'Bracelets',
          img:'157.PNG',
          description:"Bracelets",
          price:"1000",
          categories:"Bracelets",
          genre:"Homme",
      },
      {
          id:53,
          title: 'Bijoux',
          img:'159.jpg',
          description:"Bague",
          price:"1000",
          categories:"Bijoux",
          genre:"Homme",
      },
      {
        id:54,
        title: 'Bijoux',
        img:'160.JPG',
        description:"Bague",
        price:"1000",
        categories:"Bijoux",
        genre:"Homme",
    },
      {
          id:55,
          title: 'Lunettes',
          img:'166.JPG',
          description:"Lunettes",
          price:"1000",
          categories:"Lunettes",
          genre:"Homme",
      },
      {
        id:56,
        title: 'Lunettes',
        img:'167.JPG',
        description:"Lunettes",
        price:"1000",
        categories:"Lunettes",
        genre:"Homme",
    },
      {
          id:57,
          title: 'Chaussettes',
          img:'169.JPG',
          description:"Chaussettes noires",
          price:"1000",
          categories:"Chaussettes",
          genre:"Homme",
      },
      {
          id:58,
          title: 'Chaussettes',
          img:'170.jpg',
          description:"Chaussettes nike ",
          price:"1000",
          categories:"Chaussettes",
          genre:"Homme",
      },
      {
          id:59,
          title: 'Montres',
          img:'o.jpeg',
          description:"Montre Omega",
          price:"1000",
          categories:"Montres",
          genre:"Homme",
      },
      {
          id:60,
          title: 'Montres',
          img:'omega.png',
          description:"Montre Omega",
          price:"1000",
          categories:"Montres",
          genre:"Homme",
      },
      {
        id:61,
        title: 'Montres',
        img:'n.jpeg',
        description:"Montre digitale",
        price:"1000",
        categories:"Montres",
        genre:"Homme",
    },
    ],
    

}

export function categoryReducer(state = initialState, action) {
    switch (action.type) {
        case "GET-CATEGORIES-SMART": {
            return {
                ...state,categories : action.payload
            }
        }
        case "GET-ALL-ARTICLES": {
            return {
                ...state,productMens : action.payload
            }
        }
        case "GET-ARTICLE": {
            return {
                ...state,articles : action.payload
            }
        }
        case "GET-NEW-ARRIVAGES": {
            return {
                ...state,newArrivages: action.payload
            }
        }
        default: {
            return state
        }
    }
  
}
import React, { useEffect } from 'react';
import Navbar from './Navbar';
import {Link, useParams} from 'react-router-dom';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../Redux/actions';
import Carousel from 'react-elastic-carousel';
import ProductCart from '../components/ProductCart';

export default function Detail () {
  const Mens = useSelector(state=>state.categoryReducer.Mens);
  const Womens = useSelector(state=>state.categoryReducer.Womens);

  const dispatch = useDispatch();
  let id = useParams().id;
  // const detail = newsProducts.find(newsProduct => newsProduct.id == id)
  let Women =Womens.find(Women => Women.id == id);
  let Men =Mens.find(Men => Men.id == id);


  const detail = Men ? Men : Women ;
  

    const AddArticle = (id) => {
      dispatch(addToCart(id))
    }

            
    return (
     <div>
        <Navbar/>
        <div className='container'>
        <div className="container--header">
            {/* <h4 className="name--article">Iphone 14 Pro Max</h4> */}
          </div>
          <ProductCart/>
              <div className="container--article">
                <div className="container--article--left--part">
                  <div className="container--article--left--part--content">
                    <div className="container--article--left--part--container--image">  
                      <div className='container--article--left--part--container--image--box'>
                      {/* <img src={`${process.env.PUBLIC_URL}/${detail.img}`} alt="" /> */}
                      <img src={`${process.env.PUBLIC_URL}/${detail.img}`} className="d-block w-100" alt="..."/>
                      </div>
                    </div>
                    <div className="thumb">
                      <div className="thumb__images">
                        <img src="https://static.zara.net/photos///2023/V/0/2/p/6462/417/251/2/w/872/6462417251_2_1_1.jpg?ts=1678190846275" alt="" />
                      </div>
                      <div className="thumb__images">
                        <img src="https://static.zara.net/photos///2023/V/0/2/p/6462/417/251/2/w/872/6462417251_2_1_1.jpg?ts=1678190846275" alt="" />
                      </div>
                    </div>

                  </div>
                
                </div>

                <div className="container--article--right--part">
                        {/* <p className='label'>{article.title}</p> */}
                        <h4 className="name--article">{detail.description}</h4>
                        <p className="price"> {detail.price} FCFA</p>  
                        {/* <p className='label'>Marque : {article.brand}</p> */}
                        {/* <p className='reference--article'>Référence: {article.reference}</p> */}
                        {/* <p className='rating'>3 ratings</p> */}
                        {/* <p className='availability'>En stock</p> */}
                        <div className="col-3 col-md-12">
                        <select class="form-select" aria-label="Default select example">
                          <option selected>Selectionner la Taille</option>
                          <option value="1">XS</option>
                          <option value="2">S</option>
                          <option value="3">M</option>
                          <option value="3">L</option>
                        </select>
                        </div>              
                          <button className='btn--add--cart' onClick={() => AddArticle(detail)}> <i className="fa-sharp fa-solid fa-cart-plus"></i> Ajouter au panier</button>
                    </div>         
              </div>

         
        </div>
        <Footer/>
     </div>
    );
}


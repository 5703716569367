import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {addToCart, getAllArticles} from "../Redux/actions";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ProductCart from "../components/ProductCart";
import Slider from '../components/Slider';
import Marquee from "react-fast-marquee";
import Filter from "./Filter";



export default function Shoes () {
    const dispatch = useDispatch();
    const products = useSelector(state=>state.categoryReducer.products);
    const [items,setCat]=useState(products);
    const [dis,setDis]=useState(true);
    const [filter,setFilter] = useState(false);
    const closeFilter = ()=> {
          setFilter (false)
      }
      const showFilter =()=>{
          setFilter (true)
      }


    const AddArticle = (id) => {
        dispatch(addToCart(id))
      }

    const filterResult = (catItem)=>{
      const filterData = products.filter((product) =>  product.categorie === catItem )
          setCat(filterData);
          setDis(false)
  }

    useEffect(() => {
        fetch('http://127.0.0.1:8080/men')
        .then((res)=>res.json())
        .then((products)=>{dispatch(getAllArticles(products))
        })
        .catch(e => { console.log(e)})
        }, [])


//     const handleChange = (event)=>{
//             setSearch(event.target.value)
//         }
     

    return (
        <div>
                <Marquee className="marquee">
                        I can be a React component, multiple React components, or just some text.
                 </Marquee>
            <Navbar/>
            <div className="container">
            <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Shoes</li>
            </ol>
            </nav>
            <ProductCart/>
            <div className="part__products__header">
                <p className="container--header">Shoes</p>
                <p>Découvrez les nouveautés de notre collection pour homme. Vous trouverez des must-haves de tous les jours tels que hauts et t-shirts, ensembles décontractés et sous-vêtements.
                         Et pour ce qui est des pantalons pour homme, il y a des joggings et des pantalons cargo de toutes les couleurs. 
                        Vous rêvez de denim ? Notre gamme de jeans pour homme offre un large choix de coupes, dont skinny entre autres. 
                        Portez-le vôtre avec une chemise oversize tendance ou une pièce en denim de notre sélection de chemises pour homme. </p>
                </div>
                {/* <Filter setDis={setDis} dis={dis} products={products} filterResult={filterResult}/> */}
            </div>
        <div className="container-contents">
          <div className="sidebar">
          <div className="filter--container">
          <h4>CATÉGORIE</h4>
            <h6>{items.length} résultat(s)</h6>
  
             <div className="col-3 col-md-12">
                    <p className="btn__filter" onClick={()=>{filterResult('Bijoux')}}>Baskets</p>              
            </div>

            <div className="col-3 col-md-12">
                    <p className="btn__filter" onClick={()=>{filterResult('Chaussettes')}}>Bottes & Bottines</p>         
            </div>
            <div className="col-3 col-md-12">
                    <p className="btn__filter" onClick={()=>{filterResult('Lunettes')}}>Scandales & Espadrilles</p>         
            </div>
            <div className="col-3 col-md-12">
                    <p className="btn__filter" onClick={()=>{filterResult('Lunettes')}}>Mocassins</p>         
            </div>
            {/* <div className="col-3 col-md-12">
                    <p className="btn__filter" onClick={()=>{filterResult('Chapeaux')}}>Chapeaux & Casquettes</p>         
            </div> */}
                </div>
            </div>
            

       <div className="part__products">
        { dis  ? (   <div className="part__presentaions">
                                <div className="part__presentaions__pictures">
                                        <img src="https://images.unsplash.com/flagged/photo-1556637640-2c80d3201be8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80" alt="" />
                                </div>
                                <div className="part__presentaions__descriptions">
                                        <h4>NEW GAMME DE PRODUIT</h4>
                                                <p>Révélez la beauté qui sommeille en vous !</p>
                                                <button className="btn__desc">Découvrir</button>
                                </div>
                        </div>
         ) : (
       <div className="part__accessories">
          {items.map((item) => <div key={item._id}className='product__cards'>
                        <div className='product__cards__header'>
                        <p className='sales'>{item.title}</p>
                           <div className='product__cards__header__images'>
                           <Link to={`/detail/${item._id}`}><img src={`${process.env.PUBLIC_URL}/${item.img}`} alt="" /></Link>
                           </div>
                        </div>
                        <div className='product__cards__body'>
                                {/* <h5 className='product__title'>{article.title}</h5> */}
                                <p>{item.description}</p>
                               <p className='product__price'>{item.price} FCFA</p>
                               <div className='button--block'>
                               {/* <Link className='link__btn' to={`/detail/${article._id}`}><button className='btn__buy'>Acheter</button></Link> */}
                               <button className='btn__add'onClick={() => AddArticle(item)}>Ajouter au panier</button>
                                </div>  
                        </div>
                    </div> 
                        )}
                    
          </div>
         )} 
       </div>
          
          </div>
        <Footer/>
        </div>
    )
}